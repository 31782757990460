import Navigation from "./components/navigation";
import Header from "./components/sections/header";
import Footer from "./components/sections/footer";
import Contact from "./components/sections/contact";
import Cases from "./components/sections/cases";
import About from "./components/sections/about";

import "./App.css";
import Design from "./components/sections/design";

function App() {
  return (
    <div className="App">
      <Navigation></Navigation>
      <Header></Header>
      <Cases pageTitle="Hospitality"></Cases>
      <Cases pageTitle="Commercial"></Cases>
      <Cases pageTitle="Sustainable Painting"></Cases>
      <About></About>
      <Contact></Contact>
      {/*<Footer></Footer>*/}
    </div>
  );
}

export default App;
